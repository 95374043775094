<template>
  <div>
    <v-card width="500" class="mx-auto">
      <v-card-title>
        <v-icon medium>person</v-icon>
        <span class="headline">
          <b>تعديل مستخدم</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الاسم الكامل"
                  placeholder="الاسم الكامل"
                  v-model="user.fullName"
                  :rules="fullNameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الإيميل"
                  type="email"
                  placeholder="الإيميل"
                  v-model="user.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="الموبايل"
                  placeholder="00966011XXXXXX"
                  v-model="user.phoneNumber"
                  :rules="phoneNumberRules"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-select
                  v-if="role == 'Admin' || currentRole == 'Admin'"
                  v-model="selectRole"
                  :items="roles"
                  item-text="item"
                  item-value="value"
                  label="دور المستخدم"
                  :rules="rolesRules"
                  persistent-hint
                  return-object
                  requi
                  red
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="background:#5867dd;color:white;" @click="editPassword">
            <b>تعديل كلمة السر</b>
          </v-btn>
          <v-btn
            @click="save()"
            style="background:#5867dd;color:white;margin-left:5px;"
            dark
          >
            <b>تحديث</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background:gray;" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      valid: true,
      confirmPassword: "",
      fullNameRules: [
        v => !!v || "الاسم الكامل مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      nameRules: [
        v => !!v || "اسم المستخدم مطلوب",
        v =>
          (v && v.length >= 3) ||
          "اسم المستخدم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      emailRules: [
        v => !!v || "الإيميل مطلوب",
        v => /.+@.+\..+/.test(v) || "أدخل إيميل صحيح"
      ],
      phoneNumberRules: [
        v => !!v || "رقم الموبايل مطلوب",
        v =>
          (v && (v.length == 9 || v.length == 10 || v.length == 14)) ||
          "رقم الموبايل يجب أن يكون بطول 9 أو 10 أو 14 أرقام"
      ],
      editInfo: false,
      rolesRules: [v => !!v || "دور المستخدم مطلوب"],
      selectRole: "",
      role: "",
      roles: [
        { item: "مندوب مبيعات", value: "Salesman" },
        { item: "مشرف", value: "Supervisor" },
        { item: "مدير الإدارة", value: " Director" },
        { item: "رئيس قسم", value: "General Manager Of Dept" },
        { item: "أدمن", value: "Admin" },
        { item: "مدير عام", value: "CEO" }
      ],
      user: {
        password: "",
        fullName: "",
        userName: "",
        email: "",
        phoneNumber: ""
      },
      currentUser: "",
      currentRole: "",
      currentUserid: ""
    };
  },
  created() {
    this.currentUser = JSON.parse(window.localStorage.getItem("authUser"));
    this.currentUserid = JSON.parse(window.localStorage.getItem("id"));
    this.currentRole = window.localStorage.getItem("roles");
    this.getUser(this.$route.params.userId);
  },
  methods: {
    editPassword() {
      if (this.$route.params.userId == null)
        this.$router.push({ name: "edit-password" });
      else
        this.$router.push({
          name: "edit-user-password",
          params: { Id: this.user.id }
        });
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    save() {
      if (this.$refs.form.validate()) {
        let data = {
          userName: this.user.email,
          e_mail: this.user.email,
          role: this.selectRole.value,
          fullName: this.user.fullName,
          phoneNumber: this.user.phoneNumber
        };

        this.ApiService.post("Auth/EditUser?id=" + this.user.id, data)
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.push({ name: "users" });
            if (
              this.selectRole.value !== "Admin" &&
              this.user.id === this.currentUserid
            ) {
              this.onLogout();
            }
          })
          .catch(err => {
            if (err == "the email is in use !! try another  email") {
              this.hel.showSnackMsg(err, "#af1616", 2000);
            } else {
              this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
            }
          });
      }
    },
    async getUser(id) {
      await this.ApiService.get("Auth/GetUser?id=" + id)
        .then(res => {
          this.user = res.data.user;
          this.role = res.data.roles[0];
          if (this.role == "Supervisor") {
            this.selectRole = { item: "مشرف", value: "Supervisor" };
          } else if (this.role == "Salesman") {
            this.selectRole = { item: "مندوب مبيعات", value: "Salesman" };
          } else if (this.role == "Director") {
            this.selectRole = { item: "مدير إدارة", value: " Director" };
          } else if (this.role == "General Manager Of Dept") {
            this.selectRole = {
              item: "رئيس قسم",
              value: "General Manager Of Dept"
            };
          } else if (this.role == "Admin") {
            this.selectRole = { item: "أدمن", value: "Admin" };
          } else if (this.role == "CEO") {
            this.selectRole = { item: "مدير عام", value: "CEO" };
          }
        })
        .catch(() => {});
    }
  }
};
</script>
